* {
  user-select: none !important;
}
input,
text {
  user-select: text !important;
}
.text_center {
  text-align: center;
}
.display_flex,
.flex_row {
  display: flex;
}
.flex_wrap {
  flex-wrap: wrap;
}
.flex_column {
  display: flex;
  flex-direction: column;
}
.flex_center {
  display: flex;
  align-items: center;
}
.full_width {
  width: 100%;
}
.table {
  border-right: 0;
  border-bottom: 0;
  width: 100%;
}
.table .tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.table .th,
.table .td {
  padding: 10px;
  border-bottom: 1px solid #dadada;
  text-align: center;
  width: 100%;
  color: #999;
  white-space: nowrap;
}
.table .th {
  white-space: nowrap;
  font-weight: 400;
  color: #333;
}
.my_scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.my_pointer {
  cursor: pointer !important;
}
.font_13 {
  font-size: 1.3rem;
}
.margin_top_20 {
  margin-top: 20px;
}
.margin_bottom_20 {
  margin-bottom: 20px;
}
.margin_left_20 {
  margin-left: 20px;
}
.full_width {
  width: 100%;
}
.primary_color {
  color: #1890ff;
}
.ant-input-affix-wrapper .ant-input-prefix + .ant-input {
  padding-left: 40px;
}
.vueAudioNative {
  padding: 0 10px;
}
.vueAudioNative .audio-muted {
  color: #5acaf4 !important;
  display: none;
}
.vueAudioNative.large .audio-left span {
  font-size: 16px !important;
  padding: 7px 5px !important;
}
.vueAudioNative.large .audio-left .iconfont {
  color: #5acaf4;
}
.vueAudioNative.large .audio-right .slider .slider-btn {
  border-color: #5acaf4 !important;
}
.vueAudioNative.large .audio-right .slider .slider-btn:hover .tip-hover {
  background-color: #5acaf4 !important;
}
.vueAudioNative.large .audio-right .slider .slider-btn:hover .tip-hover .arrow {
  border-top-color: #5acaf4 !important;
}
.vueAudioNative.large .audio-right .slider .slider-bar {
  background-color: #5acaf4;
}
.outer_page_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.anticon-yyashow {
  color: #9da4aa;
}
.color_white {
  color: white;
}
